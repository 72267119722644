import Helmet from "react-helmet";
import ContactSection from "../../components/contactSection";
import FeatureCards from "../../components/featureCards";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import ServiceCards from "../../components/serviceCards";
import "./style.scss";
import appliedAI from "../../assets/appliedAI.svg";
import cloudmanagement from "../../assets/cloudmanagement.svg";
import cybersecurity from "../../assets/cybersecurity.svg";
import dataanalysis from "../../assets/dataanalysis.svg";
import datainsights from "../../assets/datainsights.svg";
import digitalmarketing from "../../assets/digitalmarketing.svg";
import internetofthings from "../../assets/internetofthings.svg";
import machinelearning from "../../assets/machinelearning.svg";

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Mapago - Services</title>
      </Helmet>
      <NavBar />
      <div className="services-container">
        <Hero />
        <ServicesSection />
        <Features />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

const Hero = () => {
  return (
    <>
      <section className="section-global bg-shade-1 hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-tag mb-8">Services</div>
              <h1 className="display-1">
                We help transform your business from top to bottom.
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const ServicesSection = () => {
  const services = [
    {
      name: "Applied AI",
      info: "Leverage the power of artificial intelligence to optimize your business operations and drive innovation.",
      icoUrl: appliedAI,
    },
    {
      name: "Cyber Security",
      info: "Ensure the safety and integrity of your digital assets with our comprehensive cybersecurity solutions.",
      icoUrl: cybersecurity,
    },
    {
      name: "Internet of Things",
      info: "Connect and manage your devices seamlessly to unlock new possibilities and enhance efficiency.",
      icoUrl: internetofthings,
    },
    {
      name: "Digital Marketing",
      info: "Reach your target audience effectively through strategic digital marketing campaigns tailored to your business.",
      icoUrl: digitalmarketing,
    },
    {
      name: "Cloud Management",
      info: "Optimize your cloud infrastructure for scalability, performance, and cost-efficiency with our management solutions.",
      icoUrl: cloudmanagement,
    },
    {
      name: "Machine Learning",
      info: "Harness the potential of machine learning algorithms to extract insights and make data-driven decisions.",
      icoUrl: machinelearning,
    },
    {
      name: "Data Analysis",
      info: "Unlock valuable insights from your data to drive informed business decisions and stay ahead of the competition.",
      icoUrl: dataanalysis,
    },
    {
      name: "Data Insights",
      info: "Gain actionable insights from your data to drive business growth and innovation.",
      icoUrl: datainsights,
    },
  ];

  return (
    <>
      <section className="section-global">
        <div className="container">
          <div className="row gx-md-5 gy-5">
            {services.map((service, i) => (
              <div className="col-lg-4 col-xl-3 col-md-6 col-sm-6" key={i}>
                <ServiceCards data={service} src="services" />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const Features = () => {
  const featurs_data = [
    {
      title: "Research",
      text: "Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.",
    },
    {
      title: "Build",
      text: "Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.",
    },
    {
      title: "Scale",
      text: "Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.",
    },
  ];

  return (
    <>
      <section className="section-global bg-shade-blue">
        <div className="container">
          <div className="row mb-40">
            <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2 text-center">
              <div className="section-tag mb-8">How we work</div>
              <h2>Extremely smooth workflow with cosistancy</h2>
            </div>
          </div>
        </div>
        <div className="container container-2">
          <div className="row gy-4 gx-0 gx-md-5">
            <FeatureCards data={featurs_data} src="service" />
          </div>
        </div>
      </section>
    </>
  );
};

const Contact = () => {
  const contact_data = {
    title: "Have an idea?",
    title_highlight: "Let's talk",
    text: "One of the best industry service providers with top tier talented individuals.",
    link: "/contact",
  };

  return (
    <>
      <ContactSection contact_data={contact_data} />
    </>
  );
};

export default Services;
