import Helmet from "react-helmet";
import FeatureCards from "../../components/featureCards";
import Footer from "../../components/footer";
import "./style.scss";
import NavBar from "../../components/navbar";
import ContactSection from "../../components/contactSection";
import videoThumb from "../../assets/video_thumb.jpg";
import team1 from "../../assets/team1.jpg";
import team2 from "../../assets/team2.jpg";
import team3 from "../../assets/team3.jpg";
import team4 from "../../assets/team4.jpg";

const ItSolutions = () => {
  return (
    <>
      <Helmet>
        <title>Mapago - IT Solutions</title>
      </Helmet>
      <NavBar />
      <div className="about-container">
        <Hero />
        <Features />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

const Hero = () => {
  return (
    <>
      <section className="section-global bg-shade-1 hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-tag mb-8">IT SOLUTIONS</div>
              <h1 className="display-1">
                Go digital with us, check out our IT Solutions
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Features = () => {
  const features_data = [
    {
      title: "Website Building",
      text: "Mapago is your one-stop solution for professional website development. We understand the importance of having a strong online presence in today’s digital landscape. Whether you need a simple, informative website or a complex e-commerce platform, our team of skilled developers and designers will create a website that is not only user-friendly but also functions seamlessly.",
    },
    {
      title: "Custom Software Building",
      text: "Custom software solutions are often the key to gaining a competitive edge in today’s business environment. Mapago Ventures helps you in developing tailor-made software applications that address your unique challenges and opportunities. We work closely with you to understand your specific requirements and craft software that aligns perfectly with your business goals.",
    },
    {
      title: "Application Building",
      text: "At our brand, we build innovative and user-friendly applications that cater to a wide range of needs. From mobile apps to web applications, we have the expertise to turn your ideas into reality. Our development team follows industry best practices to deliver robust and scalable applications that meet your business objectives.",
    },
    {
      title: "UI/UX Building",
      text: "User interface (UI) and user experience (UX) are critical aspects of any digital product. Our brand takes pride in creating visually appealing and intuitive designs that enhance user engagement and satisfaction. Our UI/UX experts combine creativity with usability principles to craft interfaces that not only look stunning but also provide a seamless and enjoyable user experience.",
    },
  ];

  return (
    <>
      <section className="section-global">
        <div className="container">
          <div className="row mb-40">
            <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2 text-center">
              <div className="section-tag mb-8">Avail Our Services</div>
              <h2>Extremely smooth workflow with consistancy</h2>
            </div>
          </div>
        </div>
        <div className="container container-2">
          <div className="row gy-4 gx-0 gx-md-5">
            <FeatureCards data={features_data} className="col-lg-6" />
          </div>
        </div>
      </section>
    </>
  );
};

const Team = () => {
  const team_data = [
    {
      name: "Sarah Winnemucca",
      position: "Founder & CEO",
      imgUrl: team1,
    },
    {
      name: "Alice Paul",
      position: "Founder & CTO",
      imgUrl: team2,
    },
    {
      name: "Margaret Sanger",
      position: "Chief Revenue Officer",
      imgUrl: team3,
    },
    {
      name: "Katharine Lee",
      position: "Chief Marketing Officer",
      imgUrl: team4,
    },
  ];
  return (
    <>
      <section className="section-global bg-shade-blue">
        <div className="container container-2">
          <div className="row mb-40">
            <div className="col-lg-8">
              <div className="section-tag mb-8">Team</div>
              <h2 className="mb-16">
                A team of skilled idividuals that helps you to grow
              </h2>
            </div>
          </div>
          <div className="row gy-4">
            {team_data.map((e, i) => (
              <div className="col-lg-3 col-md-4 col-sm-6" key={i}>
                <div className="team-member text-center">
                  <img
                    src={e.imgUrl}
                    className="img-fluid mb-16"
                    alt={e.name}
                  />
                  <div className="txt-2 color-1 fw-500">{e.name}</div>
                  <div className="txt-3">{e.position}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const Contact = () => {
  const contact_data = {
    title: "Have an idea?",
    title_highlight: "Let's talk",
    text: "One of the best industry service providers with top tier talented individuals.",
    link: "/contact",
  };

  return (
    <>
      <ContactSection contact_data={contact_data} />
    </>
  );
};

export default ItSolutions;
