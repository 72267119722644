import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import BrandSection from "../../components/brandSection";
import ContactSection from "../../components/contactSection";
import Footer from "../../components/footer";
import NavBar from "../../components/navbar";
import ServiceCards from "../../components/serviceCards";
import "./style.scss";
import culture1 from "../../assets/culture_1.png";
import culture2 from "../../assets/culture_2.png";
import culture3 from "../../assets/culture_3.png";
import service1 from "../../assets/service_ico1.svg";
import service2 from "../../assets/service_ico2.svg";
import service3 from "../../assets/service_ico3.svg";
import service4 from "../../assets/service_ico4.svg";
import heroImage from "../../assets/hero_image.png";
import about1 from "../../assets/about1.png";
import about2 from "../../assets/about2.png";

import {
  FaHospital,
  FaMoneyBill,
  FaIndustry,
  FaShoppingBag,
  FaBuilding,
  FaGraduationCap,
  FaSeedling,
  FaHotel,
} from "react-icons/fa";

const Consulting = () => {
  return (
    <>
      <Helmet>
        <title>Mapago - Consulting</title>
      </Helmet>
      <NavBar />
      <div className="consulting">
        <Hero />
        <Services />
        <About />
        <Culture />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

const Hero = () => {
  const brands = [
    {
      name: "HEALTHCARE",
      icon: <FaHospital />,
    },
    {
      name: "FINTECH",
      icon: <FaMoneyBill />,
    },
    {
      name: "MANUFACTURING",
      icon: <FaIndustry />,
    },
    {
      name: "RETAIL",
      icon: <FaShoppingBag />,
    },
    {
      name: "REAL ESTATE",
      icon: <FaBuilding />,
    },
    {
      name: "EDUCATION",
      icon: <FaGraduationCap />,
    },
    {
      name: "AGRICULTURE",
      icon: <FaSeedling />,
    },
    {
      name: "HOSPITALITY",
      icon: <FaHotel />,
    },
  ];

  return (
    <>
      <section className="section-global no-border">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-6 align-self-center">
              <h1>
                We provide end to end{" "}
                <span style={{ color: "#418edd" }}>Digital solutions</span> to
                businesses and startups
              </h1>
              <p className="txt-1 mt-16 me-lg-5">
                Become a part of this digital revolution today with the best in
                class solutions of Mapago.
              </p>
              <div style={{ display: "flex", gap: "1rem" }} className="mt-20">
                <Link to="/about" className="btn btn-outline-primary btn-lg">
                  Explore More
                </Link>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <img src={heroImage} className="img-fluid" alt="hero" />
            </div>
          </div>
        </div>
      </section>
      <BrandSection
        customTitle={"INDUSTRIES WE WORK WITH"}
        brands={brands}
        bordered
      />
    </>
  );
};

const Services = () => {
  const services = [
    {
      name: "Make your Business Digital",
      info: "Take your business into the digital age with our comprehensive solutions. We'll help you implement modern technologies and effective digital strategies to boost your online presence and enhance customer engagement. From building a user-friendly website to launching targeted digital marketing campaigns, we'll guide you every step of the way to ensure your business thrives in the digital landscape.",
      link: "/services",
      icoUrl: service2,
    },
    {
      name: "Grow your business",
      info: "Expand your reach and increase market share through strategic digital marketing initiatives tailored to your business goals.",
      link: "/services",
      icoUrl: service1,
    },
    {
      name: "Improve online presence",
      info: "Enhance your visibility and credibility across digital platforms, ensuring that your brand stands out and attracts potential customers effectively.",
      link: "/services",
      icoUrl: service4,
    },
    {
      name: "Cyber Security",
      info: "Protect your digital assets and sensitive information from cyber threats with our comprehensive cybersecurity solutions, safeguarding your business against potential breaches.",
      link: "/services",
      icoUrl: service3,
    },
  ];

  return (
    <>
      <section className="section-global">
        <div className="container">
          <div className="row mb-40">
            <div className="col-xl-6 col-lg-6 offset-xl-3 offset-lg-3 text-center">
              <div className="section-tag mb-8">What we do</div>
              <h2>Best in class innovative tech solutions</h2>
              <div className="row justify-content-center mt-4">
                <div className="col-auto">
                  <Link
                    to="/services"
                    style={{ borderRadius: "5px !important !important" }}
                    className="btn  btn-sm"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {services.map((service, i) => (
              <div
                className="col-lg-4 col-xl-3 col-md-6 col-sm-6 gx-5 gy-4"
                key={i}
              >
                <ServiceCards data={service} />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const About = () => {
  const features_data = [
    "Seamless, comprehensive, and cost-effective",
    "Connect with your customers",
    "Streamline your operations",
  ];

  return (
    <>
      <section className="section-global bg-shade-1">
        <div className="container">
          <div className="row mb-40 gy-4">
            <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "600px" }}>
                <img src={about1} className="img-fluid" alt="About" />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Growth</div>
              <h2 className="mb-16">We always ensure customer satisfaction</h2>
              <p className="txt-1">
                Our simple process helps us stay nimble, evolve rapidly and
                ultimately grow brands. We empower digital brands to grow faster
                by providing services in growth marketing + operations and
                venture capital.
                <br />
                <br />
                We cut through the clutter to uncover new opportunities, and
                always ensure customer satisfaction
              </p>
            </div>
          </div>
          <div className="row pt-5 gy-4">
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Solutions</div>
              <h2 className="mb-16">
                Deliver personalized business tatics at scale
              </h2>
              <p className="txt-1">
                We craft digital experiences that resonate with your audience
                and grow your business. If you have a vision for the next big
                thing, we can help you make it happen.{" "}
              </p>
              {features_data.map((e, i) => (
                <div
                  key={i}
                  className="txt-2 color-1 fw-500 mb-8 d-flex align-items-center"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="me-2"
                  >
                    <rect width="24" height="24" rx="12" fill="#0FA958" />
                    <path
                      d="M6.66675 12L10.6667 16L17.3334 8"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  {e}
                </div>
              ))}
            </div>
            <div className="col-lg-6 align-self-center d-flex justify-content-center align-items-center">
              <div style={{ maxWidth: "600px" }}>
                <img src={about2} className="img-fluid" alt="About" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Culture = () => {
  const culture_data = [
    {
      imgUrl: culture1,
      title: "Industry Experts",
      text: "Our simple process helps us stay nimble, evolve rapidly and ultimately grow brands.",
    },
    {
      imgUrl: culture2,
      title: "Growth oriented",
      text: "We empower digital brands to grow faster by providing services in growth marketing and operations.",
    },
    {
      imgUrl: culture3,
      title: "Team spirit",
      text: "We cut through the clutter to uncover new opportunities, and always ensure customer satisfaction.",
    },
  ];

  return (
    <>
      <section className="section-global">
        <div className="container container-2">
          <div className="row mb-40">
            <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2 text-center">
              <div className="section-tag mb-8">Culture</div>
              <h2>
                We aspire to be one of the most loved companies in the world
              </h2>
            </div>
          </div>
          <div className="row">
            {culture_data.map((e, i) => (
              <div className="col-lg-4 col-md-6 col-sm-6 text-center" key={i}>
                <img src={e.imgUrl} className="img-fluid" alt={e.title} />
                <h3>{e.title}</h3>
                <p className="txt-2">{e.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const Contact = () => {
  const contact_data = {
    title: "Have an idea?",
    title_highlight: "Let's talk",
    text: "One of the best industry service providers with top tier talented individuals.",
    link: "/contact",
  };

  return (
    <>
      <ContactSection contact_data={contact_data} />
    </>
  );
};

export default Consulting;
