import React from "react";
import Helmet from "react-helmet";
import Footer from "../../components/footer";
import "./style.scss";
import NavBar from "../../components/navbar";
import heroImage from "../../assets/hero_cyber.webp";
import comprehensivedataprotection from "../../assets/comprehensivedataprotection.png";
import networksecuritysystem from "../../assets/networksecuritysystem.png";
import threatdetection from "../../assets/threatdetection.png";
import cybersecurityconsulting from "../../assets/cybersecurityconsulting.png";
import endtoendcybersecuritysolutions from "../../assets/endtoendcybersecuritysolutions.png";
import whychooseus from "../../assets/whychooseus.png";
import ContactSection from "../../components/contactSection";

const CyberSecurity = () => {
  return (
    <>
      <Helmet>
        <title>Mapago - CyberSecurity</title>
      </Helmet>
      <NavBar />
      <div className="app-landing">
        <Hero />
        <Showcase />
        <EndCTA />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

const Hero = () => {
  return (
    <>
      <section
        style={{ backgroundColor: "#5bbdf5" }}
        className="section-global hero"
      >
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-6 align-self-center">
              <h1 className="mb-16">CyberSecurity</h1>
              <p className="txt-1 mb-20">
                Mapago Ventures offers a wide range of cybersecurity solutions
                to meet the needs of any organization. Our solutions include
                network security, endpoint security, application security, data
                security, cloud security, and managed security services.
              </p>
            </div>
            <div className="col-lg-6 d-flex justify-content-center">
              <div style={{ maxWidth: "485px" }}>
                <img src={heroImage} className="img-fluid" alt="hero" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Showcase = () => {
  // Define an array of objects for showcase data
  const showcaseData = [
    {
      image: comprehensivedataprotection,
      title: "Comprehensive Data Protection",
      description:
        "Mapago offers end-to-end data protection solutions, including data encryption, secure backups, and data access control, to safeguard sensitive information from breaches and unauthorized access",
    },
    {
      image: networksecuritysystem,
      title: "Network Security System",
      description:
        "We work closely with organizations to design and implement customized network security measures, conduct vulnerability assessments, deploy firewalls, and monitor network traffic to ensure secure and resilient digital infrastructure.",
    },
    {
      image: threatdetection,
      title: "24/7 Threat Detection and Response",
      description:
        "Mapago provides round-the-clock threat detection and incident response services, swiftly identifying and neutralizing threats to protect digital assets.",
    },
    {
      image: cybersecurityconsulting,
      title: "CyberSecurity Consulting",
      description:
        "Beyond core services, we offer expert cybersecurity consulting, collaborating with organizations to develop customized cybersecurity strategies, policies, and training programs to create a culture of digital security.",
    },
    {
      image: endtoendcybersecuritysolutions,
      title: "End to End Cybersecurity Solutions",
      description:
        "Mapago Ventures provides comprehensive cybersecurity solutions, from strategy development to implementation, ensuring that digital assets are safeguarded in the ever-evolving digital landscape.",
    },
  ];

  return (
    <>
      <section className="section-global">
        <div className="container container-2">
          {showcaseData.map((item, index) => (
            <div
              className={`row gy-4 gx-0 gx-lg-5 feature-row-margin ${
                index % 2 === 0 ? "" : "flex-row-reverse"
              }`}
              key={index}
            >
              <div className="col-lg-6">
                <img
                  src={item.image}
                  className="img-fluid"
                  alt={`showcase${index + 1}`}
                />
              </div>
              <div className="col-lg-6 align-self-center">
                <h2 className="mb-16 display-2">{item.title}</h2>
                <p className="txt-1">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

const EndCTA = () => {
  return (
    <>
      <section className="section-global end_cta">
        <div className="container container-1">
          <div className="row w-100 gy-4 gx-5">
            <div className="col-lg-6 col-xl-7 align-self-center order-1 order-lg-0">
              <div className="section-tag mb-8">Why Choose us</div>
              <h2 className="mb-16">
                <span className="txt-highlight">More then 50</span> businesses
                believe in us
              </h2>
              <ul className="txt-3 mb-20 gy-2">
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Proven track record:
                  </span>{" "}
                  Mapago Ventures has a proven track record of success in
                  protecting our clients from cyber threats. We have helped
                  hundreds of businesses to protect their data and systems from
                  cyberattacks.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Comprehensive solutions:
                  </span>{" "}
                  Mapago Ventures offers a comprehensive range of cybersecurity
                  solutions to meet the needs of any organization. We can help
                  you to protect your networks, endpoints, applications, data,
                  and cloud environment
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Experienced team:</span>{" "}
                  Mapago Ventures’ team of cybersecurity experts is highly
                  skilled and experienced. We have a deep understanding of the
                  latest cyber threats and how to protect against them.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Committed to service:
                  </span>{" "}
                  Mapago Ventures is committed to providing our clients with the
                  best possible service and support. We are available 24/7 to
                  help you with any cybersecurity needs you may have.
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-xl-5 text-center">
              <img src={whychooseus} className="img-fluid" alt="download" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Contact = () => {
  const contact_data = {
    title: "Have an idea?",
    title_highlight: "Let's talk",
    text: "One of the best industry service providers with top tier talented individuals.",
    link: "/contact",
  };

  return (
    <>
      <ContactSection contact_data={contact_data} />
    </>
  );
};

export default CyberSecurity;
