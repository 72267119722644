import Helmet from "react-helmet";
import FeatureCards from "../../components/featureCards";
import Footer from "../../components/footer";
import "./style.scss";
import NavBar from "../../components/navbar";
import ContactSection from "../../components/contactSection";
import videoThumb from "../../assets/video_thumb.jpg";

const About = () => {
  return (
    <>
      <Helmet>
        <title>Mapago - About</title>
      </Helmet>
      <NavBar />
      <div className="about-container">
        <Hero />
        <AboutSection />
        <Features />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

const Hero = () => {
  return (
    <>
      <section className="section-global bg-shade-1 hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-tag mb-8">About Mapago</div>
              <h1 className="display-1">
                We grow your business by providing best in class digital
                solutions.
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const AboutSection = () => {
  const capabilities = [
    {
      title: "IT",
      text: "Providing comprehensive IT solutions to streamline your business operations.",
    },
    {
      title: "Marketing",
      text: "Innovative marketing strategies tailored to boost your brand's visibility and engagement.",
    },
    {
      title: "Cybersecurity",
      text: "Ensuring robust protection against cyber threats with cutting-edge security measures.",
    },
  ];

  return (
    <>
      <section className="section-global">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 align-self-center d-flex justify-content-center">
              <div className="video-thumb">
                <a href="/">
                  <img src={videoThumb} className="img-fluid" alt="Mapago" />
                </a>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="section-tag mb-8">Who we are</div>
              <h2 className="mb-16">Best in class innovative tech solutions</h2>
              <p className="txt-1 mb-20">
                We craft digital experiences that resonate with your audience
                and grow your business. If you have a vision for the next big
                thing, we can help you make it happen.{" "}
              </p>
              <h3 className="mb-16">Our core capabilities</h3>
              <div>
                <div className="row gy-4">
                  {capabilities.map((e, i) => (
                    <div className="col-xl-6 col-lg-12 col-md-6" key={i}>
                      <div className="d-flex capability-card" key={i}>
                        <div className="count">{i + 1}</div>
                        <div className="ms-2">
                          <div className="txt-2 fw-700 color-1">{e.title}</div>
                          <div className="txt-3">{e.text}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Features = () => {
  const features_data = [
    {
      title: "Research",
      text: "In-depth analysis and market research drive our strategies, fostering agility and brand growth.",
    },
    {
      title: "Build",
      text: "Implementing innovative solutions and technologies to construct robust foundations for brand success.",
    },
    {
      title: "Scale",
      text: "Utilizing scalable frameworks and strategies to facilitate rapid expansion and brand development.",
    },
  ];

  return (
    <>
      <section className="section-global">
        <div className="container">
          <div className="row mb-40">
            <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2 text-center">
              <div className="section-tag mb-8">How we work</div>
              <h2>Extremely smooth workflow with consistancy</h2>
            </div>
          </div>
        </div>
        <div className="container container-2">
          <div className="row gy-4 gx-0 gx-md-5 align-items-center justify-content-center">
            <FeatureCards data={features_data} />
          </div>
        </div>
      </section>
    </>
  );
};

const Contact = () => {
  const contact_data = {
    title: "Have an idea?",
    title_highlight: "Let's talk",
    text: "One of the best industry service providers with top tier talented individuals.",
    link: "/contact",
  };

  return (
    <>
      <ContactSection contact_data={contact_data} />
    </>
  );
};

export default About;
