import "./style.scss";
const BrandSection = ({ brands, light, bordered, bg, pt, customTitle }) => {
  return (
    <section
      className={
        (light ? "" : "dark") +
        (bg ? ` ${bg}` : "") +
        " brand-section-container"
      }
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className={
                "brand-section " +
                (bordered ? "bordered" : "") +
                (pt ? "pt-60" : "")
              }
            >
              <h2 className="txt-6 fw-500 text-center mb-20">
                {customTitle ? customTitle : "Trusted by"}
              </h2>
              <marquee>
                <div className="d-flex gap-5">
                  {brands.map((brand, index) => (
                    <div
                      className="d-flex flex-row gap-2 align-items-center justify-content-center"
                      style={{ fontSize: "2rem" }}
                      key={index}
                    >
                      <div
                        className="d-flex sectag flex-row gap-2 align-items-center justify-content-center"
                        style={{
                          fontWeight: "bold",
                          height: "4rem",
                        }}
                      >
                        {brand.icon}
                      </div>
                      <div
                        className="d-flex sectag flex-row gap-2 align-items-center justify-content-center"
                        style={{
                          fontWeight: "bold",
                          height: "4rem",
                        }}
                      >
                        {brand.name}
                      </div>
                    </div>
                  ))}
                </div>
              </marquee>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrandSection;
