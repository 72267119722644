import Helmet from "react-helmet";
import FeatureCards from "../../components/featureCards";
import Footer from "../../components/footer";
import "./style.scss";
import NavBar from "../../components/navbar";
import ContactSection from "../../components/contactSection";

const Marketing = () => {
  return (
    <>
      <Helmet>
        <title>Mapago - Marketing Solutions</title>
      </Helmet>
      <NavBar />
      <div className="about-container">
        <Hero />
        <Features />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

const Hero = () => {
  return (
    <>
      <section className="section-global bg-shade-1 hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="section-tag mb-8">MARKETING SOLUTIONS</div>
              <h1 className="display-1">
                We do marketing that increases your revenue and grow your
                business.
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Features = () => {
  const features_data = [
    {
      title: "Search Engine Marketing",
      text: "Mapago provides a complete Search Engine Marketing service, focusing on both on-page and off-page SEO. Our experts optimize your website’s content and structure (on-page) for better search rankings, while also using advanced off-page techniques like link building and social media engagement to enhance your industry authority.",
    },
    {
      title: "Email Marketing",
      text: "Bulk email service is a great tool for enhancing your digital marketing initiatives at Mapago. We can better manage your customers and increase your conversion rates by categorizing them into separate groups and determining their needs.",
    },
    {
      title: "Content Marketing",
      text: "We research the preferences and needs of our target audiences globally. Our content’s top priority is to meet the needs of your users. Our professionally crafted content, which is SEO-aligned and generates organic traffic, will help your website maintain a high ranking in Google search results.",
    },
    {
      title: "Lead Generation",
      text: "Three times a week, we review the data, and the findings are fed back into our internal campaigns to help us revise our plans and optimize for highly profitable, high-set-rate campaigns. We use reliable statistics to monitor and improve the success of your marketing initiatives. Your most lucrative Zips, counties, and cities are known to us before they are known to you.",
    },
    {
      title: "Branding Services",
      text: "In a competitive market with numerous options for clients, we understand the importance of setting your brand out. At Mapago Ventures, we specialize in developing and enhancing the identity and reputation of your brand. We gain a deeper understanding of your customers and organization through the collection and strategic use of zero-party data, which enables us to provide a customized customer experience.",
    },
    {
      title: "Search Engine Optimization",
      text: "We rely on data-driven strategies! We are dedicated to significantly increasing your organic search leads, positioning your business into the digital era, influencing consumer choices, encouraging self-directed purchases, and increasing local traffic, all of which will enhance the graph of your visibility and engagement metrics.",
    },
    {
      title: "Social Media Marketing",
      text: "Our Social Media Marketing service is your digital success partner. We master the social media landscape, boosting your online presence with creative, data-driven strategies. We craft engaging content, connect with your audience, and amplify your brand’s voice across platforms. We’re here to make your brand a social media powerhouse, driving growth and influence in the digital world.",
    },
  ];

  return (
    <>
      <section className="section-global">
        <div className="container">
          <div className="row mb-40">
            <div className="col-xl-6 col-lg-8 offset-xl-3 offset-lg-2 text-center">
              <div className="section-tag mb-8">Avail Our Services</div>
              <h2>Extremely smooth workflow with consistancy</h2>
            </div>
          </div>
        </div>
        <div className="container container-2">
          <div className="row gy-4 gx-0 gx-md-5">
            <FeatureCards data={features_data} className="col-lg-6" />
          </div>
        </div>
      </section>
    </>
  );
};

const Contact = () => {
  const contact_data = {
    title: "Have an idea?",
    title_highlight: "Let's talk",
    text: "One of the best industry service providers with top tier talented individuals.",
    link: "/contact",
  };

  return (
    <>
      <ContactSection contact_data={contact_data} />
    </>
  );
};

export default Marketing;
