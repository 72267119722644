import React from "react";
import "./style.scss";

const ServiceCards = ({ data }) => {
  return (
    <div className="service-card p-4">
      <div className="service-icon mb-8">
        <img src={data.icoUrl} className="mb-8" alt={data.name} />
      </div>
      <h3>{data.name}</h3>
      <p className="txt-2 mb-8">{data.info}</p>
    </div>
  );
};

export default ServiceCards;
