import { useEffect } from "react";
import Helmet from "react-helmet";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import Consulting from "./pages/consulting";
// import NotFound from './pages/404';
import About from "./pages/about";
import ItSolutions from "./pages/itsolutions";
import Marketing from "./pages/marketing";
import CyberSecurity from "./pages/cybersecurity";
import Contact from "./pages/contact";
import Services from "./pages/services";

// import Blog from './pages/blog';
// import BlogArticle from './pages/blogArticle';

// import Contact from './pages/contact';
// import FAQs from './pages/FAQs';
// import Pricing from './pages/pricing';
// import ProjectDetails from './pages/projectDetails';
// import Projects from './pages/projects';
// import Services from './pages/services';

function App() {
  return (
    <>
      <Helmet>
        <title>Mapago - React Landing Template</title>
        <meta
          name="description"
          content="Multipurpose agency and potfolio react template"
        />
      </Helmet>
      <div className="Mapago-container">
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Consulting />} />
            <Route path="/about" element={<About />} />
            <Route path="/it-solution" element={<ItSolutions />} />
            <Route path="/marketing-solution" element={<Marketing />} />
            <Route path="/cybersecurity" element={<CyberSecurity />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            {/* 
              
              <Route path='/pricing' element={<Pricing/>} />
              <Route path='/faqs' element={<FAQs/>} />
              <Route path='/projects' element={<Projects/>} />
              <Route path='/project-detail' element={<ProjectDetails/>} />
              <Route path='/blog' element={<Blog/>} />
              <Route path='/blog-article' element={<BlogArticle/>} />
              <Route path='*' element={<NotFound/>} /> */}
          </Routes>
        </Router>
      </div>
    </>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

export default App;
